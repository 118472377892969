import axios from 'axios'
import convert from 'xml-js'
import store from '../store/index'
import emitter from '@/services/emitter.js'

const FIELDS = {
    PI: 'PI',
    LABEL: 'LABEL',
    THUMBNAIL: 'THUMBNAIL',
    YEAR: 'YEAR',
    CENTURY: 'CENTURY',
    MD_UNIGOE_STANDORT_GEOKOORDINATEN: 'MD_UNIGOE_STANDORT_GEOKOORDINATEN',
    MD_UNIGOE_ORTE: 'MD_UNIGOE_ORTE',
    MD_UNIGOE_MATERIAL_INDEX: 'MD_UNIGOE_MATERIAL_INDEX',
    MD_UNIGOE_MATERIAL_ANZEIGE: 'MD_UNIGOE_MATERIAL_ANZEIGE',
    MD_UNIGOE_OBJEKTGATTUNG: 'MD_UNIGOE_OBJEKTGATTUNG',
    MD_UNIGOE_SCHLAGWORTE: 'MD_UNIGOE_SCHLAGWORTE',
    MD_UNIGOE_PLACES_WITH_CONTENT: 'MD_UNIGOE_PLACES_WITH_CONTENT',
    MD_UNIGOE_PERSONEN: 'MD_UNIGOE_PERSONEN',
    MD_UNIGOE_MASSE_UMFANG: 'MD_UNIGOE_MASSE_UMFANG',
    ISWORK: 'ISWORK',
    MD_UNIGOE_DC_ONLY_ID: 'MD_UNIGOE_DC_ONLY_ID',
    DIMENSIONS: 'FACET_UNIGOE_MASSE_UMFANG',
    MD_UNIGOE_STIL_EPOCHE: 'MD_UNIGOE_STIL_EPOCHE',
    MD_UNIGOE_BESCHREIBUNG: 'MD_UNIGOE_BESCHREIBUNG',
}

const defaultResultFields = [
    FIELDS.PI,
    FIELDS.LABEL,
    FIELDS.THUMBNAIL,
    FIELDS.MD_UNIGOE_MATERIAL_INDEX,
    FIELDS.MD_UNIGOE_MATERIAL_ANZEIGE,
    FIELDS.MD_UNIGOE_OBJEKTGATTUNG,
    FIELDS.MD_UNIGOE_ORTE,
    FIELDS.MD_UNIGOE_PLACES_WITH_CONTENT,
    FIELDS.MD_UNIGOE_SCHLAGWORTE,
    FIELDS.MD_UNIGOE_STANDORT_GEOKOORDINATEN,
    FIELDS.MD_UNIGOE_STIL_EPOCHE,
    FIELDS.MD_UNIGOE_PERSONEN,
    FIELDS.CENTURY,
    FIELDS.YEAR,
    FIELDS.DIMENSIONS,
    FIELDS.MD_UNIGOE_BESCHREIBUNG,
]

const queryCreator = (query, resultFields, count, randomize) => {
    return {
        query,
        resultFields: resultFields ? resultFields : defaultResultFields,
        count: count ? count : -1,
        randomize: randomize ? randomize : false,
    }
}
const fetchAsBlob = url =>
    fetch(url, { mode: 'cors' }).then(response => {
        return response.blob()
    })

export default {
    manifest: {
        baseURL() {
            return store.getters.getSetting('collection_base_url')
        },
        async getManifest(PI) {
            return axios.get(`${this.baseURL()}/api/v1/records/${PI}/manifest/`)
        },
        async getManifestContent(manifestUrl) {
            return axios.get(manifestUrl)
        },
    },
    goettingen: {
        baseURL() {
            return store.getters.getSetting('collection_base_url')
        },
        queryURL() {
            return this.baseURL() + '/api/v1/index/query/'
        },
        async getAllItems() {
            return axios.post(
                this.queryURL(),
                queryCreator(
                    `+${FIELDS.ISWORK}:true && THUMBNAIL: * && ${FIELDS.MD_UNIGOE_MASSE_UMFANG}: *`,
                    defaultResultFields,
                ),
            )
        },
        async getItem(PI) {
            return this.getItems([PI])
        },

        async getItems(PIs) {
            let condition = ''
            PIs.forEach((PI, index) => {
                condition += `PI:${PI} ${index < PIs.length - 1 ? ' || ' : ''}`
            })
            return axios.post(
                this.queryURL(),
                queryCreator(
                    `+${FIELDS.ISWORK}:true && +(${condition})`,
                    defaultResultFields,
                ),
            )
        },
        async getRandomItems(count, collectionId) {
            let query = `+${FIELDS.ISWORK}:true && THUMBNAIL:*`
            if (collectionId) {
                query += ` && +${FIELDS.MD_UNIGOE_DC_ONLY_ID}:${collectionId}`
            }
            return axios.post(
                this.queryURL(),
                queryCreator(query, defaultResultFields, count, true),
            )
        },

        async getCollection(id) {
            return axios.post(
                this.queryURL(),
                queryCreator(
                    `+${FIELDS.ISWORK}:true && +${FIELDS.MD_UNIGOE_DC_ONLY_ID}:${id}`,
                ),
            )
        },

        async getThumbnail(item, width = 400, height = 400) {
            return axios.get(
                `${this.baseURL()}/api/v1/records/${item.PI}/files/images/${
                    item.THUMBNAIL
                }/full/!${width},${height}/0/default.jpg`,
                { responseType: 'arraybuffer' },
            )
        },
        getThumbnailUrl(item, width = 400, height = 400) {
            if (item) {
                return `${this.baseURL()}/api/v1/records/${
                    item.PI
                }/files/images/${
                    item.THUMBNAIL
                }/full/!${width},${height}/0/default.jpg`
            }
        },
        getIsolatedThumbnailUrl(item, size = 100) {
            if (item) {
                return `http://meta.dev.kustodie.uni-goettingen.de/image/v2/${item.PI}/files/images/${item.THUMBNAIL}/full/${size},/0/transparent-background.png`
            }
        },
        getBase64(url) {
            fetch(url)
                .then(response => response.json())
                .then(data => console.log(data))

            fetch()
            return axios.get(url, {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                },
                responseType: 'arraybuffer',
            })
            // .then(response =>
            //     Buffer.from(response.data, 'binary').toString('base64'),
            // )
        },

        async getRelatedItemsByProperty(item, field, count, random = true) {
            // TODO: item[field] is array
            const payload = queryCreator(
                `+${FIELDS.ISWORK}:true && +${field}:${item[field]} && NOT PI:${item.PI}`,
                defaultResultFields,
                count,
                random,
            )
            return axios
                .post(this.queryURL(), payload)
                .then(response => response.data.docs)
        },
        async getRelatedItemsByPropertyAndValue(
            item,
            field,
            value,
            count,
            random = true,
        ) {
            const payload = queryCreator(
                `+${FIELDS.ISWORK}:true && ${FIELDS.THUMBNAIL}:* && +${field}:${value} && NOT PI:${item.PI}`,
                defaultResultFields,
                count,
                random,
            )
            return axios
                .post(this.queryURL(), payload)
                .then(response => response.data.docs)
        },
        async getRelatedItemsByPropertyValuePairs(
            item,
            properties,
            count,
            operator = '&&',
        ) {
            // TODO: item[field] is array
            let query = `+${FIELDS.ISWORK}:true && NOT PI:${item.PI} && ${FIELDS.THUMBNAIL}:*`
            properties.forEach(property => {
                query =
                    query + ` ${operator} +${property.field}:${property.value}`
            })
            const payload = queryCreator(
                query,
                defaultResultFields,
                count,
                true,
            )
            return axios
                .post(this.queryURL(), payload)
                .then(response => response.data.docs)
        },
        async getCoins() {
            return axios.post(this.queryURL(), {
                query:
                    '+ISWORK: true && +MD_UNIGOE_DC_ONLY_ID:slg_1024 && +MD_UNIGOE_OBJEKTGATTUNG:Münze/Medaille && (LABEL:*byz* || LABEL:*Byz* || MD_UNIGOE_BESCHREIBUNG: *byz* || MD_UNIGOE_BESCHREIBUNG: *Byz*)',
                resultFields: defaultResultFields,
                count: 5000,
            })
        },
    },
    kenom: {
        async get(PI) {
            const data = await axios.get(
                `https://www.kenom.de/oai/?verb=GetRecord&metadataPrefix=lido&identifier=${PI}`,
            )
            // TODO: check if compact representation is enough
            return JSON.parse(convert.xml2json(data.data))
        },
    },
    twoavy: {
        baseURL: process.env.VUE_APP_BASE_URL,
        config: {
            headers: { 'X-DIRECT': process.env.VUE_APP_X_DIRECT },
        },
        postingStayAtUnit: [],
        createInterceptor() {
            axios.interceptors.response.use(
                response => response,
                error => {
                    if (window.electronApi) {
                        let errorData = {
                            url: error.response.config.url,
                            msg: error.response.data.error,
                        }
                        window.electronApi.send(
                            'reportError',
                            JSON.stringify(errorData),
                        )
                    }
                    if (error.response.data.code === 409) {
                        emitter.emit('logout-user', error.response.data.error)
                    }
                    //alert(error)
                    throw error
                },
            )
        },
        async getUuid() {
            return axios.get(this.baseURL + '/fowi/create-uuid', this.config)
        },
        async collectItem(uuid, PI) {
            return axios.post(
                `${this.baseURL}/fowi/${uuid}/collect-item`,
                { pid: PI },
                this.config,
            )
        },
        async collectItems(uuid, PIs) {
            const promises = []
            PIs.forEach(PI => {
                promises.push(this.collectItem(uuid, PI))
            })
            return await Promise.all(promises)
                .then(() => {
                    return { success: true }
                })
                .catch(error => {
                    console.log(error)
                })
        },
        async unCollectItem(uuid, PI) {
            return axios.post(
                `${this.baseURL}/fowi/${uuid}/uncollect-item`,
                { pid: PI },
                this.config,
            )
        },
        async unCollectItems(uuid, PIs) {
            const promises = []
            PIs.forEach(PI => {
                promises.push(this.unCollectItem(uuid, PI))
            })
            return await Promise.all(promises)
                .then(() => {
                    return { success: true }
                })
                .catch(error => {
                    console.log(error)
                })
        },
        async getCollectedItems(uuid) {
            return axios.get(
                `${this.baseURL}/fowi/${uuid}/get-collected-items`,
                this.config,
            )
        },
        async loginAtUnit(uuid, unit) {
            return axios.post(
                `${this.baseURL}/fowi/${uuid}/login-at-unit`,
                { unit: unit },
                this.config,
            )
        },
        async logoutFromUnit(unit) {
            return axios.post(
                `${this.baseURL}/fowi/logout-from-unit`,
                { unit: unit },
                this.config,
            )
        },
        async stayAtUnit(uuid, unit) {
            if (this.postingStayAtUnit.indexOf(unit) < 0) {
                this.postingStayAtUnit.push(unit)
                axios
                    .post(
                        `${this.baseURL}/fowi/stay-at-unit`,
                        { uuid, unit },
                        this.config,
                    )
                    .then(response => {
                        const index = this.postingStayAtUnit.indexOf(unit)
                        if (index >= 0) {
                            this.postingStayAtUnit.splice(index, 1)
                        }
                        return response
                    })
                    .catch(error => {
                        console.log(error)
                        const index = this.postingStayAtUnit.indexOf(unit)
                        if (index >= 0) {
                            this.postingStayAtUnit.splice(index, 1)
                        }
                        return null
                    })
            }
        },
        async checkUnitOccupied(unit) {
            return axios.post(
                this.baseURL + '/fowi/check-unit-occupied',
                { unit: unit },
                this.config,
            )
        },
        async getNfcTags() {
            return axios.get(
                this.baseURL + '/fowi/get-nfc-tags?all',
                this.config,
            )
        },
        async getInterfaceTexts() {
            return axios.get(
                this.baseURL + '/sections/2/structures?all',
                this.config,
            )
        },
        async getMarket() {
            return axios.get(
                this.baseURL + '/sections/10/structures?all',
                this.config,
            )
        },
        async getCupboardsTaxonomy() {
            return axios.get(
                this.baseURL + '/sections/5/structures?all',
                this.config,
            )
        },
        async getCupboardsCollectionNames() {
            return axios.get(
                this.baseURL + '/fowi/cabinets/impossible-collections',
                this.config,
            )
        },
        async saveCupboardsCollectionName(user, name, items) {
            return axios.post(
                this.baseURL + '/fowi/cabinets/impossible-collections',
                {
                    uuid: user.uuid,
                    language: user.settings.language,
                    name,
                    items,
                },
                this.config,
            )
        },
        async getUserLanguage(uuid) {
            return axios.get(
                `${this.baseURL}/fowi/${uuid}/user-settings`,
                this.config,
            )
        },
        async getLanguages() {
            return axios.get(this.baseURL + '/languages', this.config)
        },
        async getContent(sectionId) {
            if (!sectionId) {
                return null
            }
            return axios.get(
                `${this.baseURL}/sections/${sectionId}/structures?all&inlineAssets=true`,
                this.config,
            )
        },
        async getAsset(assetId) {
            return axios.get(`${this.baseURL}/assets/${assetId}`, this.config)
        },
        async getApp() {
            return axios.get(this.baseURL + '/app', this.config)
        },
        async getCollectableItems(unit) {
            return axios.get(
                this.baseURL + `/fowi/get-collectable-items?unit=${unit}`,
                this.config,
            )
        },
        async getMarketRanking() {
            return axios.get(
                process.env.VUE_APP_BASE_URL + '/fowi/market/ranking',
                this.config,
            )
        },
        async setMarketRanking(uuid, marketSession, type, items) {
            return axios.post(
                process.env.VUE_APP_BASE_URL + '/fowi/market/ranking',
                { uuid, items: items, type, marketSession },
                this.config,
            )
        },
        async getMarketScores() {
            return axios.get(
                process.env.VUE_APP_BASE_URL + '/fowi/market/score',
                this.config,
            )
        },
        async setMarketScores(uuid, marketSession, type, items, score) {
            return axios.post(
                process.env.VUE_APP_BASE_URL + '/fowi/market/score',
                { uuid, items: items, type, marketSession, score },
                this.config,
            )
        },
        async createAtelierImage(type, uuid, image) {
            return axios.post(
                process.env.VUE_APP_BASE_URL + '/fowi/atelier/images',
                { type, uuid, image },
                this.config,
            )
        },
        async getAtelierImages(type) {
            return axios.get(
                process.env.VUE_APP_BASE_URL +
                    '/fowi/atelier/images?type=' +
                    type,
                this.config,
            )
        },
        async deleteDeskNewAnnotation(id) {
            return axios.delete(
                process.env.VUE_APP_BASE_URL + '/fowi/desk/annotations/' + id,
                this.config,
            )
        },
        async postDeskNewAnnotation(payload) {
            return axios.post(
                process.env.VUE_APP_BASE_URL + '/fowi/desk/annotations',
                payload,
                this.config,
            )
        },
        async putDeskNewAnnotation(id, payload) {
            return axios.put(
                process.env.VUE_APP_BASE_URL + '/fowi/desk/annotations/' + id,
                payload,
                this.config,
            )
        },
        async getDeskAnnotations() {
            return axios.get(
                process.env.VUE_APP_BASE_URL + '/fowi/desk/annotations?&all=1',
                this.config,
            )
        },
        async getSaloonDebates() {
            return axios.get(
                process.env.VUE_APP_BASE_URL + '/fowi/salon/shows',
                this.config,
            )
        },
        async loginUser(username, password) {
            return axios.post(
                process.env.VUE_APP_BASE_URL + '/users/login',
                { username: username, password: password },
                this.config,
            )
        },
        async loginUserWithoutMobile(unit, createCollectionFor, random = 5) {
            return axios.post(
                process.env.VUE_APP_BASE_URL + '/fowi/login-at-unit',
                { unit, createCollectionFor, random },
                this.config,
            )
        },
    },
}

export { FIELDS, fetchAsBlob }
