import Vue from 'vue'
import Vuex from 'vuex'
import api from '../../mixins/api'

Vue.use(Vuex)

export default {
    namespaced: true,
    state: {
        interfaceTexts: null,
    },
    mutations: {
        setInterfaceTexts(state, content) {
            state.interfaceTexts = content
        },
    },
    actions: {
        async setInterfaceTexts({ commit }) {
            const interfaceTexts = await api.twoavy.getInterfaceTexts()
            commit('setInterfaceTexts', interfaceTexts?.data?.data)
        },
    },
    getters: {
        getInterfaceText: state => key => {
            if (state.interfaceTexts) {
                // console.log(state.interfaceTexts)
                const filteredInterfaceTexts = state.interfaceTexts.filter(
                    x => x.content.key,
                )
                const interfaceText = filteredInterfaceTexts.find(
                    x => x.content.key.global === key,
                )
                if (interfaceText) {
                    return interfaceText.content.value
                }
            }
            return null
        },
    },
}
