import Vue from 'vue'
import VueI18n from 'vue-i18n'
import api from './mixins/api'

Vue.use(VueI18n)

const i18n = new VueI18n({
    locale: 'de',
    messages: {
        de: {
            test: 'test',
            fowi: 'FOWI',
        },
    },
})

const getMessages = async () => {
    return new Promise(resolve => {
        return api.twoavy.getInterfaceTexts().then(response => {
            const data = response.data.data
            const messages = {}
            data.forEach(text => {
                const localizationKey = text.content.key
                const values = text.content.value
                const entries = Object.entries(values ? values : {})
                entries.forEach(entry => {
                    const languageKey = entry[0]
                    const value = entry[1]
                    if (!Object.keys(messages).includes(languageKey)) {
                        messages[languageKey] = {}
                    }
                    if (localizationKey?.global) {
                        messages[languageKey][localizationKey.global] = value
                    }
                })
            })

            return resolve(messages)
        })
    })
}

api.twoavy.getInterfaceTexts().then(response => {
    const data = response.data.data
    const messages = {}
    data.forEach(text => {
        const localizationKey = text.content.key
        const values = text.content.value
        const entries = Object.entries(values ? values : {})
        entries.forEach(entry => {
            const languageKey = entry[0]
            const value = entry[1]
            if (!Object.keys(messages).includes(languageKey)) {
                messages[languageKey] = {}
            }
            if (localizationKey?.global) {
                messages[languageKey][localizationKey.global] = value
            }
        })
    })

    Object.keys(messages).forEach(key => {
        i18n.setLocaleMessage(key, messages[key])
    })
})

const setLocale = locale => {
    i18n.locale = locale
}

export default i18n
export { setLocale, getMessages }
