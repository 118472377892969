const getInitialState = () => {
    return {
        usersProgress: [],
        carRoutes: [],
    }
}
export default {
    namespaced: true,
    state: getInitialState(),
    mutations: {
        resetState(state) {
            Object.assign(state, getInitialState())
        },
        setCarRoutes(state, routes) {
            // console.log(routes)
            state.carRoutes = routes
        },
        setUsersProgress(state, progress) {
            // console.log(progress)

            let temp = state.usersProgress.find(
                u => u.userId === progress.userId,
            )
            // state.usersProgress = []
            // console.log(temp)
            if (temp) {
                let index = state.usersProgress.indexOf(temp)
                // console.log(index)
                state.usersProgress[index] = progress
            } else {
                state.usersProgress.push(progress)
            }
            // console.log(state.usersProgress)
        },
        resetUsersProgress(state, userId) {
            let temp = state.usersProgress.find(u => u.userId === userId)
            // console.log(temp)
            let deleteIndex = state.usersProgress.indexOf(temp)

            // console.log(deleteIndex)
            if (deleteIndex > -1) {
                state.usersProgress.splice(deleteIndex, 1)
            }
            // console.log(state.usersProgress)
        },
    },
    actions: {
        resetState({ commit }) {
            commit('resetState')
        },
        setUsersProgress({ commit }, progress) {
            commit('setUsersProgress', progress)
        },
        resetUsersProgress({ commit }, userId) {
            commit('resetUsersProgress', 'knowledgeNetworks_' + userId)
        },
        setCarRoutes({ commit }, routes) {
            commit('setCarRoutes', routes)
        },
    },
    getters: {},
}
