import Vue from 'vue'
import Vuex from 'vuex'
import assets from './assets/assets'
import interfaceTexts from './interfaceTexts/interfaceTexts'

// stations
import knowledgeNetworks from './stations/2.002_knowledgeNetworks/knowledgeNetworks'
import cupboards from './stations/3.001_cupboards/cupboards'
import cupboardsGameGenerator from './stations/3.001_cupboards/gameGenerator'
import cupboardsGameHaystack from './stations/3.001_cupboards/gameHaystack'
import cupboardsGameTaxonomy from './stations/3.001_cupboards/gameTaxonomy'
import desk from './stations/3.004_desk/desk'
import atelier from './stations/3.005_atelier/atelier'
import saloon from './stations/3.006_saloon/saloon'
import market from './stations/3.009_market/market'
import items from './items'

// api
import api from '../mixins/api'
import { uniq } from 'lodash'

async function getGoettCollection(collection, collectables, content) {
    const uniqCollection = uniq(collection)
    return Promise.all(
        uniqCollection.map(async collectedItem => {
            const goettItem = (await api.goettingen.getItem(collectedItem)).data
                .docs[0]
            let nfcItem = []
            let manifest = {}
            let twoavyElement = null
            if (collectables) {
                nfcItem = collectables.find(x => x.identifier === collectedItem)
                if (content && nfcItem) {
                    twoavyElement = content.find(
                        x =>
                            x.content?.object_pi?.global ===
                            nfcItem.structureId,
                    )
                    if (nfcItem.manifest_url) {
                        await fetch(nfcItem.manifest_url)
                            .then(response => response.json())
                            .then(data => {
                                manifest = data
                            })
                    }
                }
            }
            return {
                id: collectedItem,
                goettItem,
                nfcItem,
                content: twoavyElement ? twoavyElement : null,
                manifest: manifest ? manifest : null,
                thumbnailUrl: manifest.thumbnail
                    ? manifest.thumbnail['@id']
                    : api.goettingen.getThumbnailUrl(goettItem),
                fullsizeUrl: api.goettingen.getThumbnailUrl(
                    goettItem,
                    10000,
                    10000,
                ),
            }
        }),
    )
}

Vue.use(Vuex)

const UNITS = {
    DESK1: 'desk1',
    DESK2: 'desk2',
    ATELIER: 'atelier',
}

const getInitialState = () => {
    return {
        app: null,
        content: null,
        confirmPopup: null,
        users: {},
        languages: [],
        language: null,
        collectedItems: [],
        collectables: null,
        units: UNITS,
        unit: null,
        unitConfig: null,
        nfcTags: null,
        settings: [],
    }
}

export default new Vuex.Store({
    state: getInitialState(),
    mutations: {
        async setUser(state, user) {
            if (user.collection) {
                user.collection = await getGoettCollection(
                    user.collection,
                    state.collectables,
                    state.content,
                )
                Vue.set(state.users, user.unit, user)
            }
        },
        unsetUser(state, unit) {
            Vue.delete(state.users, unit)
        },
        setLanguages(state, languages) {
            state.languages = languages
        },
        setLanguage(state, lang) {
            state.language = lang
        },
        setCollectedItems(state, items) {
            state.collectedItems = items
        },
        setNfcTags(state, nfcTags) {
            state.nfcTags = nfcTags
        },
        setApp(state, app) {
            state.app = app
        },
        setUnit(state, unit) {
            state.unit = unit
        },
        setUnitConfig(state, unitConfig) {
            state.unitConfig = unitConfig
        },
        setCollectabels(state, collectables) {
            state.collectables = collectables
        },
        setContent(state, content) {
            state.content = content
        },
        setPopup(state, content) {
            state.confirmPopup = content
        },
        resetPopup(state) {
            state.confirmPopup = null
        },
        setSettings(state, settings) {
            state.settings = settings
        },
    },
    actions: {
        setUser({ commit }, user) {
            commit('setUser', user)
        },
        unsetUser({ commit }, unit) {
            commit('unsetUser', unit)
        },
        stayAtUnit({ state }, unit) {
            const user = state.users[unit]
            if (user) {
                api.twoavy.stayAtUnit(user.uuid, user.unit)
            }
        },
        async setLanguages({ commit }) {
            const languages = await api.twoavy.getLanguages()
            commit('setLanguages', languages?.data?.data)
        },
        setLanguage({ commit }, language) {
            commit('setLanguage', language)
        },
        setCollectedItems({ commit }, items) {
            commit('setCollectedItems', items)
        },
        setUnit({ commit }, unit) {
            commit('setUnit', unit)
        },
        setUnitConfig({ commit }, unitConfig) {
            commit('setUnitConfig', unitConfig)
        },
        async getAndSetNfcTags({ commit }) {
            const allNfcTags = await api.twoavy.getNfcTags()
            commit('setNfcTags', allNfcTags?.data)
            commit(
                'setCollectabels',
                allNfcTags?.data?.filter(x => x.type === 'collectable'),
            )
        },
        async getApp({ commit }) {
            const app = await api.twoavy.getApp()
            commit('setApp', app.data.data)
        },
        async getContent({ commit }, sectionId) {
            const content = await api.twoavy.getContent(sectionId)
            commit('setContent', content?.data?.data)
        },
        setConfirmPopup({ commit }, content) {
            commit('setPopup', content)
        },
        resetConfirmPopup({ commit }) {
            commit('resetPopup')
        },
        async getSettings({ commit }) {
            const settingsFromApi = await api.twoavy.getContent(23)
            const settings = settingsFromApi.data?.data?.map(setting => {
                return {
                    key: setting.content.key.global,
                    value: setting.content.value.global,
                }
            })
            commit('setSettings', settings)
        },
    },
    getters: {
        isAUserLoggedIn: state => {
            return Object.keys(state.users).length > 0
        },
        getUserAtUnit: state => unit => {
            return state.users[unit]
        },
        getUuid: state => unit => {
            return state.users[unit]?.uuid
        },
        getUserLanguage: state => (index = 0) => {
            return state.users[index]?.settings?.language
        },
        getUserUnit: state => (index = 0) => {
            return state.users[index]?.unit
        },
        getUnitConfig: state => {
            return state.unitConfig
        },
        getContent: state => {
            return state.content
        },
        getNfcTags: state => {
            return state.nfcTags
        },
        getConfirmPopup: state => {
            return state.confirmPopup
        },
        getSetting: state => (key = '') => {
            if (
                state.settings &&
                state.settings.length > 0 &&
                state.settings.find(x => x.key === key)
            ) {
                return state.settings.find(x => x.key === key).value
            }
            return false
        },
    },
    modules: {
        items,
        assets,
        interfaceTexts,
        cupboards,
        cupboardsGameGenerator,
        cupboardsGameHaystack,
        cupboardsGameTaxonomy,
        desk,
        atelier,
        knowledgeNetworks,
        market,
        saloon,
    },
})
