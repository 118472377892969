import store from '../store'
import axios from 'axios'

const baseURL = process.env.VUE_APP_BASE_URL
const config = {
    headers: { 'X-DIRECT': process.env.VUE_APP_X_DIRECT },
}

export default {
    async ASSETS_getAsset(id, raw = false) {
        const url = raw
            ? baseURL + '/assets/' + id + '?raw'
            : baseURL + '/assets/' + id
        console.log(url)
        const response = await axios.get(url, config)
        await store.dispatch('assets/setAsset', response.data.data)
        return response.data.data
    },
    getAssetPath(id, conversion = 'url') {
        const asset = store.state.assets.assets.find(x => x.id === id)
        if (asset) {
            if (conversion === 'raw') {
                if (asset.raw === '') {
                    return 'no raw data provided'
                }
                return asset.raw
            } else {
                if (asset.paths === '') {
                    return 'no paths provided'
                }
                switch (conversion) {
                    case 'url':
                        return asset.paths.url
                    case 'full_hd':
                        return asset.paths.full_hd_url
                    case '640':
                        return asset.paths.w640_url
                }
            }
        } else {
            const raw = conversion === 'raw'
            this.ASSETS_getAsset(id, raw)
        }
    },
    getAssetData(id) {
        if (store.state.assets.assets.find(x => x.id === id)) {
            const asset = store.state.assets.assets.find(x => x.id === id)
            return asset
        } else {
            this.ASSETS_getAsset(id)
        }
    },
    getLanguage(langObject) {
        return langObject[store.state.language]
            ? langObject[store.state.language]
            : langObject[0]
    },
    getInterfaceText(key, lang) {
        const interfaceText = store.getters['interfaceTexts/getInterfaceText'](
            key,
        )
        if (interfaceText) {
            if (lang) {
                return interfaceText[lang]
            } else {
                return this.getLanguage(interfaceText)
            }
        }
        return 'text not found ' + key
    },
    getAudioAssetLanguage(langObject) {
        /*
        const currentLang = this.$store.getters.languages.find(
            x => x.title === this.$store.getters.currentLanguage,
        )
        if (currentLang) {
            const asset = langObject.find(x => x.lang === currentLang.code)
            return asset ? asset : langObject[0]
        }
         */
        return langObject[0]
    },
}
