import api from '../../../mixins/api'
import { isEqual } from '../../../utils/items'
import { GAMES } from './cupboards'
import { stationsData } from '../../../stationsData'

const CONCLUSIONDURATION = 10000
const ANIMATIONDURATION = 20000

const STEPS = {
    SELECT: 'SELECT',
    CONCLUSION: 'CONCLUSION',
    SAVED: 'SAVED',
}
const stayAtUnit = rootGetters => {
    const unit = `cupboards`
    const userUuid = rootGetters.getUuid(unit)
    api.twoavy.stayAtUnit(userUuid, unit)
}
const getInitialState = () => {
    return {
        item: null,
        items: [],
        selectedItems: [null, null, null, null],
        name: '',
        step: STEPS.SELECT,
    }
}

const getName = (items, language) => {
    let adjective = ''
    let noun = ''
    items.forEach((item, index) => {
        const keys = ['adjective_0', 'adjective_1', 'noun_0', 'noun_1_plural']
        const key = keys[index]
        if (!item) {
            return
        }
        const contentByKey = item.cupboardsTaxonomyItem.content[key]
        if (contentByKey) {
            const availableLanguages = Object.keys(contentByKey)
            if (!availableLanguages.includes(language)) {
                language = 'de'
            }
            switch (index) {
                case 0: {
                    adjective = contentByKey[language]
                    break
                }
                case 1: {
                    if (adjective.length > 0) {
                        adjective += ` ${contentByKey[language]}`
                    } else {
                        adjective = contentByKey[language]
                    }
                    break
                }
                case 2: {
                    noun = contentByKey[language]
                    break
                }
                case 3: {
                    if (noun.length > 0) {
                        noun += `-${contentByKey[language]}`
                    } else {
                        noun = contentByKey[language]
                    }
                    break
                }
            }
        }
    })
    if (adjective.length + noun.length === 0) {
        return ''
    }
    return `${adjective} ${noun}`.trim()
}
export default {
    namespaced: true,
    state: getInitialState(),
    mutations: {
        resetState(state) {
            const items = [...state.items]
            Object.assign(state, getInitialState())
            state.items = items
        },
        setItems(state, items) {
            state.items = items
        },
        setItem(state, item) {
            state.item = state.items.find(
                i => i.goettItem?.PI === item.goettItem?.PI,
            )
        },
        setName(state, name) {
            state.name = name
        },
        setSelectedItem(state, { item, index }) {
            const selectedItems = [...state.selectedItems]

            // if two items change position
            const originalIndex = selectedItems.findIndex(
                i => i?.goettItem.PI === item.goettItem.PI,
            )
            if (selectedItems[index]) {
                selectedItems[originalIndex] = selectedItems[index]
            } else {
                selectedItems[originalIndex] = null
            }
            selectedItems[index] = item
            state.selectedItems = selectedItems
            state.name = getName(selectedItems, 'de')
        },
        unsetSelectedItem(state, item) {
            const selectedItems = state.selectedItems.map(i => {
                return isEqual(i, item) ? null : i
            })
            state.selectedItems = selectedItems
            state.name = getName(selectedItems, 'de')
        },
        setStep(state, step) {
            state.step = step
        },
    },
    actions: {
        resetState({ commit }) {
            commit('resetState')
        },
        setItems({ commit }, items) {
            commit('setItems', items)
        },
        setItem({ commit }, item) {
            commit('setItem', item)
        },
        setSelectedItem({ commit, rootGetters }, payload) {
            stayAtUnit(rootGetters)
            commit('setSelectedItem', payload)
        },
        unsetSelectedItem({ commit, rootGetters }, item) {
            stayAtUnit(rootGetters)
            commit('unsetSelectedItem', item)
        },
        saveCollection({ commit, dispatch, state, rootGetters }) {
            api.twoavy.saveCupboardsCollectionName(
                rootGetters.getUserAtUnit('cupboards'),
                state.name,
                state.selectedItems.map(i => i?.cupboardsTaxonomyItem.id),
            )
            commit('setStep', STEPS.CONCLUSION)
            setTimeout(() => {
                commit('setStep', STEPS.SAVED)
            }, CONCLUSIONDURATION)
            setTimeout(() => {
                dispatch('cupboards/setGame', GAMES.NONE, { root: true })
                commit('setStep', STEPS.SELECT)
            }, ANIMATIONDURATION)
        },
        async getItems({ state, commit }) {
            if (state.items.length === 0) {
                const cupboardItems = (
                    await api.twoavy.getContent(
                        stationsData.find(
                            station => station.key === 'cupboards',
                        ).sectionId,
                    )
                ).data.data
                const cupboardsTaxonomyItems = cupboardItems.filter(
                    item => item.templateId === 21,
                )
                const items = await Promise.all(
                    cupboardsTaxonomyItems.map(async item => {
                        return {
                            cupboardsTaxonomyItem: item,
                            goettItem: (
                                await api.goettingen.getItems([item.title])
                            ).data.docs[0],
                        }
                    }),
                )
                // console.log(items.map(i => i.goettItem.PI))
                // const invalidItems = items.filter(item => !item)
                // console.log('invalid items', invalidItems)
                commit(
                    'setItems',
                    items.filter(item => item.goettItem),
                )
            }
        },
    },
    getters: {
        isSelected: state => item => {
            let isSelected = false
            state.selectedItems.forEach(i => {
                if (isEqual(i, item)) {
                    isSelected = true
                }
            })
            return isSelected
        },
        getIndex: state => item => {
            let selectedIndex = -1
            state.selectedItems.forEach((i, index) => {
                if (isEqual(i, item)) {
                    selectedIndex = index
                }
            })
            return selectedIndex
        },
    },
}

export { STEPS }
