const isEqual = (first, second) => {
    return first?.goettItem?.PI === second?.goettItem?.PI
}

const normalizeProperty = property => {
    const words = property.split(' ')
    if (words.length < 3) return null
    let key = words[0]
    let value = words[1].replace(/,/g, '.')
    let unit = words[2]
    if (property.startsWith('Gewicht')) {
        key = 'Gewicht'
        if (unit === 'kg') {
            value = value * 1000
        }
        unit = 'g'
    } else if (property.startsWith('Durchmesser')) {
        key = 'Durchmesser'
        if (unit === 'cm') {
            value = value * 10
        } else if (unit === 'm') {
            value = value * 1000
        }
        unit = 'mm'
    } else if (property.startsWith('Höhe')) {
        key = 'Höhe'
        if (unit === 'cm') {
            value = value * 10
        } else if (unit === 'm') {
            value = value * 1000
        }
        unit = 'mm'
    } else if (property.startsWith('Breite')) {
        key = 'Breite'
        if (unit === 'cm') {
            value = value * 10
        } else if (unit === 'm') {
            value = value * 1000
        }
        unit = 'mm'
    } else if (property.startsWith('Tiefe')) {
        key = 'Tiefe'
        if (unit === 'cm') {
            value = value * 10
        } else if (unit === 'm') {
            value = value * 1000
        }
        unit = 'mm'
    } else if (property.startsWith('Länge')) {
        key = 'Länge'
        if (unit === 'cm') {
            value = value * 10
        } else if (unit === 'm') {
            value = value * 1000
        }
        unit = 'mm'
    }
    return { key, value, unit, initial: property }
}

const getWeightInG = item => {
    const properties = item.FACET_UNIGOE_MASSE_UMFANG
    let weight = 0
    if (!properties) {
        console.log(
            `item ${item.PI} does not have FACET_UNIGOE_MASSE_UMFANG property, returning 0`,
        )
        return weight
    }
    properties.forEach(property => {
        const normalizedProperty = normalizeProperty(property)
        if (normalizedProperty && normalizedProperty.key === 'Gewicht') {
            weight = normalizedProperty.value
        }
    })
    return weight
}
const getDiameterInMm = item => {
    const properties = item.FACET_UNIGOE_MASSE_UMFANG
    let diameter = 0
    if (!properties) {
        console.log(
            `item ${item.PI} does not have FACET_UNIGOE_MASSE_UMFANG property, returning 0`,
        )
        return diameter
    }
    properties.forEach(property => {
        const normalizedProperty = normalizeProperty(property)
        if (normalizedProperty && normalizedProperty.key === 'Durchmesser') {
            diameter = normalizedProperty.value
        }
    })
    return diameter
}

const getVolumeInCmm = item => {
    let volume = 0
    const properties = item.FACET_UNIGOE_MASSE_UMFANG
    if (!properties) {
        console.log(
            `item ${item.PI} does not have FACET_UNIGOE_MASSE_UMFANG property, returning 0`,
        )
        return volume
    }

    const normalizedProperties = properties.map(property =>
        normalizeProperty(property),
    )

    const height = normalizedProperties.find(property => {
        return property?.key === 'Höhe'
    })
    const depth = normalizedProperties.find(property => {
        return property?.key === 'Tiefe'
    })
    const length = normalizedProperties.find(property => {
        return property?.key === 'Länge'
    })
    const width = normalizedProperties.find(property => {
        return property?.key === 'Breite'
    })
    const diameter = normalizedProperties.find(property => {
        return property?.key === 'Durchmesser'
    })

    if (height && width && depth) {
        return height.value * width.value * depth.value
    } else if (height && width && length) {
        return height.value * width.value * length.value
    } else if (height && width) {
        return height.value * width.value * 10
    } else if (width && length) {
        return length.value * width.value * 10
    } else if (height && length) {
        return height.value * length.value * 10
    } else if (diameter && length) {
        return Math.PI * Math.pow(diameter.value / 2, 2) * length.value
    } else if (diameter) {
        return Math.PI * Math.pow(diameter.value / 2, 2) * 10
    } else if (height) {
        return height.value * 10 * 10
    }

    /*
in mm
breite, hoehe: quaderberechnung mit tiefe 10mm
breite, hoehe, tiefe: quaderberechnung
hoehe, breite, laenge: quaderberechnung
durchmesser: kugelberechnung
durchmesser, hoehe: zylinderbrechnung
*/

    return volume
}

const getMaxLengthInMm = item => {
    let volume = 0
    const properties = item.FACET_UNIGOE_MASSE_UMFANG
    if (!properties) {
        console.log(
            `item ${item.PI} does not have FACET_UNIGOE_MASSE_UMFANG property, returning 0`,
        )
        return volume
    }

    const normalizedProperties = properties.map(property =>
        normalizeProperty(property),
    )

    const height = normalizedProperties.find(property => {
        return property?.key === 'Höhe'
    })
    const depth = normalizedProperties.find(property => {
        return property?.key === 'Tiefe'
    })
    const length = normalizedProperties.find(property => {
        return property?.key === 'Länge'
    })
    const width = normalizedProperties.find(property => {
        return property?.key === 'Breite'
    })
    const diameter = normalizedProperties.find(property => {
        return property?.key === 'Durchmesser'
    })

    let maxLength = 0
    if (height && height.value > maxLength) {
        maxLength = height.value
    }
    if (width && width.value > maxLength) {
        maxLength = width.value
    }
    if (length && length.value > maxLength) {
        maxLength = length.value
    }
    if (depth && depth.value > maxLength) {
        maxLength = depth.value
    }
    if (diameter && diameter.value > maxLength) {
        maxLength = diameter.value
    }
    return maxLength
}

const hasDimensions = item => {
    const properties = item?.FACET_UNIGOE_MASSE_UMFANG
    if (!properties) {
        return false
    }
    return getMaxLengthInMm(item) > 0
}

export {
    getWeightInG,
    getDiameterInMm,
    getMaxLengthInMm,
    getVolumeInCmm,
    isEqual,
    hasDimensions,
}
