import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import('../views/Home.vue'),
    },
    {
        path: '/settings',
        name: 'settings',
        component: () => import('../views/Settings.vue'),
    },
    {
        path: '/knowledgeNetworks',
        name: 'knowledgeNetworks',
        component: () =>
            import(
                '../components/stations/2.002_knowledgeNetworks/KnowledgeNetworksMain.vue'
            ),
    },
    {
        path: '/cupboards',
        name: 'cupboards',
        component: () =>
            import('../components/stations/3.001_cupboards/CupboardsMain.vue'),
    },
    {
        path: '/desk',
        name: 'desk',
        component: () =>
            import('../components/stations/3.004_desk/DeskMain.vue'),
    },
    {
        path: '/atelier',
        name: 'atelier',
        component: () =>
            import('../components/stations/3.005_atelier/AtelierMain.vue'),
    },
    {
        path: '/market',
        name: 'market',
        component: () =>
            import('../components/stations/3.009_market/MarketMain.vue'),
    },
]

const router = new VueRouter({
    routes,
})

export default router
