import api, { FIELDS } from '../../../mixins/api'
import { getVolumeInCmm, hasDimensions } from '../../../utils/items'
import { debounce } from 'lodash'

const STEPS = {
    IDLE: 'IDLE',
    INTRODUCTION: 'INTRODUCTION',
    SELECT: 'SELECT',
    CONCLUSION: 'CONCLUSION',
    FINISH: 'FINISH',
}

const stayAtUnit = async rootGetters => {
    const unit = 'cupboards'
    const userUuid = rootGetters['getUuid'](unit)
    await api.twoavy.stayAtUnit(userUuid, unit)
}
const debouncedStayAtUnit = debounce(stayAtUnit, 5000, {
    maxWait: 10000,
    leading: true,
})

const getInitialState = () => {
    return {
        item: null,
        selectedCategory: null,
        step: STEPS.IDLE,
        selectedProps: {},
        maxVolumeInCbm: 10,
        featuredItem: null,
        relatedItems: {},
    }
}
export default {
    namespaced: true,
    state: getInitialState(),
    mutations: {
        resetState(state) {
            Object.assign(state, getInitialState())
        },
        async setItem(state, item) {
            state.item = item
            state.step = STEPS.INTRODUCTION
        },
        setStep(state, step) {
            state.step = step
        },
        setNextStep(state) {
            const step = state.step
            switch (step) {
                case STEPS.INTRODUCTION: {
                    state.step = STEPS.SELECT
                    break
                }
                case STEPS.SELECT: {
                    state.step = STEPS.FINISH
                    break
                }
            }
        },
        confirmSelection(state) {
            state.step = STEPS.FINISH
        },
        setRelatedItems(state, { category, property, items }) {
            const relatedItems = { ...state.relatedItems }
            if (!relatedItems[category]) {
                relatedItems[category] = {}
            }
            relatedItems[category][property] = items
            state.relatedItems = relatedItems
        },
        setFeaturedItem(state, item) {
            state.featuredItem = item
        },
        setSelectedProp(state, { key, value }) {
            const selectedProps = { ...state.selectedProps }
            Object.keys(selectedProps).forEach(key => {
                selectedProps[key] = null
            })
            selectedProps[key] = value
            state.selectedProps = selectedProps
            if (value == null) {
                state.selectedCategory = null
            } else {
                state.selectedCategory = key
            }
            state.step = STEPS.SELECT
        },
        setSelectedCategory(state, category) {
            state.selectedCategory = category
        },
        clearSelectedProps(state) {
            const selectedProps = { ...state.selectedProps }
            Object.keys(selectedProps).forEach(key => {
                selectedProps[key] = null
            })
            state.selectedProps = selectedProps
            state.selectedCategory = null
        },
    },
    actions: {
        resetState({ commit }) {
            commit('resetState')
        },
        setItem({ commit }, item) {
            commit('setItem', item)
            const categories = [
                FIELDS.MD_UNIGOE_OBJEKTGATTUNG,
                FIELDS.CENTURY,
                FIELDS.MD_UNIGOE_MATERIAL_INDEX,
                FIELDS.MD_UNIGOE_SCHLAGWORTE,
                FIELDS.MD_UNIGOE_ORTE,
                FIELDS.MD_UNIGOE_PERSONEN,
            ]
            categories.forEach(category => {
                const properties = item.goettItem[category]
                properties?.forEach(property => {
                    api.goettingen
                        .getRelatedItemsByPropertyAndValue(
                            item,
                            category,
                            property,
                            -1,
                            true,
                        )
                        .then(response => {
                            commit('setRelatedItems', {
                                category,
                                property,
                                items: response
                                    .filter(i => hasDimensions(i))
                                    .map(i => {
                                        return { goettItem: i }
                                    }),
                            })
                        })
                })
            })
        },
        setStep({ commit, rootGetters }, step) {
            debouncedStayAtUnit(rootGetters)
            commit('setStep', step)
        },
        setNextStep({ commit }) {
            commit('setNextStep')
        },
        confirmSelection({ commit, rootGetters }) {
            debouncedStayAtUnit(rootGetters)
            commit('confirmSelection')
        },
        setFeaturedItem({ commit }, item) {
            commit('setFeaturedItem', item)
        },
        async setSelectedProp({ commit, state, rootGetters }, { key, value }) {
            debouncedStayAtUnit(rootGetters)
            if (value) {
                await commit('setSelectedCategory', key)
            } else {
                await commit('setSelectedCategory', null)
            }
            await commit('setSelectedProp', { key, value })
            await commit('setFeaturedItem', null)
        },
        async clearSelectedProps({ commit }) {
            commit('clearSelectedProps')
        },
        async setSelectedCategory({ commit }, category) {
            commit('setSelectedCategory', category)
        },
        async setSelectedCategoryAndProp(
            { commit, rootGetters, state },
            { category, prop },
        ) {
            debouncedStayAtUnit(rootGetters)
            if (prop) {
                await commit('setSelectedCategory', category)
            }
            commit('setSelectedCategory', category)
            commit('setSelectedProp', { key: category, value: prop })
        },
    },
    getters: {
        getStep: state => {
            return state.step
        },
        isStep: state => step => {
            return state.step === step
        },
        getVolume: state => {
            let volume = 0
            if (state.selectedProps) {
                Object.keys(state.selectedProps).forEach(key => {
                    if (
                        state.selectedProps[key] != null &&
                        state.relatedItemsByCategory[key]
                    ) {
                        state.relatedItemsByCategory[key].forEach(item => {
                            volume += parseFloat(getVolumeInCmm(item.goettItem))
                        })
                    }
                })
            }
            return volume
        },
        getMaxVolumeInCbm: state => {
            return state.maxVolumeInCbm
        },
        getCategoriesCount: state => {
            return Object.keys(state.relatedItemsByCategory).length
        },
        getSelectedCategory: state => {
            return state.selectedCategory
        },
        getSelectedPropValue: state =>
            state.selectedProps[state.selectedCategory],
        hasItemCategory: state => category => {
            return state.item.goettItem[category]
        },
        getRelatedItems: state => {
            const category = state.selectedCategory
            try {
                const cat = JSON.parse(
                    JSON.stringify(state.relatedItems[category]),
                )
                const prop = JSON.parse(JSON.stringify(state.selectedProps))[
                    category
                ]
                if (!category || !cat || !cat[prop]) {
                    console.log(
                        'get related items',
                        cat,
                        state.selectedProps,
                        prop,
                    )
                    return []
                }
                return cat[prop]
            } catch {
                return []
            }
        },
        getFillPercentage: state => {
            let volume = 0
            const category = state.selectedCategory
            const prop = state.selectedProps[state.selectedCategory]
            const relatedItems = state.relatedItems[category][prop]
            if (!relatedItems) {
                return 0
            }
            relatedItems.forEach(i => {
                volume += parseFloat(getVolumeInCmm(i.goettItem))
            })
            if (volume === 0) {
                return 0
            }
            return Math.min(
                (volume / state.maxVolumeInCbm / 1000 / 1000 / 1000) * 100,
                100,
            )
        },
        isAnyCategorySelected: state => state.selectedCategory != null,
        isCategoryAndPropSelected: state => (category, prop) => {
            return (
                state.selectedCategory === category &&
                state.selectedProps[state.selectedCategory] === prop
            )
        },
        hasCategoryEnoughRelatedItems: state => category => {
            const enough = 3
            // const relatedItems = await api.goettingen.getRelatedItemsByProperty(state.item, category, 5, true)
            const cat = state.relatedItems[category]
            let hasEnoughRelatedItems = false
            if (cat) {
                const props = Object.keys(cat)
                props.forEach(prop => {
                    if (cat[prop]?.length > enough) {
                        hasEnoughRelatedItems = true
                    }
                })
            }
            return hasEnoughRelatedItems
        },
        hasCategoryAndPropEnoughRelatedItems: state => (category, prop) => {
            const enough = 3
            // const relatedItems = await api.goettingen.getRelatedItemsByProperty(state.item, category, 5, true)
            const cat = state.relatedItems[category]
            if (cat) {
                return cat[prop]?.length > enough
            }
            return false
        },
    },
}

export { STEPS }
