import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import metrics from './mixins/metrics'
import i18n from './i18n'
import { BootstrapVue } from 'bootstrap-vue'
import { dom, library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fal } from '@fortawesome/pro-light-svg-icons'
import { far } from '@fortawesome/pro-regular-svg-icons'
import { fas } from '@fortawesome/pro-solid-svg-icons'

Vue.config.productionTip = false

library.add(fal, far, fas)
Vue.component('FontAwesomeIcon', FontAwesomeIcon)
dom.watch()

const metricsUse = {
    install(Vue) {
        Vue.prototype.$metrics = metrics
    },
}
Vue.use(metricsUse)
Vue.use(require('vue-shortkey'))
Vue.use(require('vue2-animate/dist/vue2-animate.min.css'))
Vue.use(BootstrapVue)

new Vue({
    router,
    store,
    i18n,
    render: h => h(App),
}).$mount('#app')
