const stationsData = [
    {
        key: 'knowledgeNetworks',
        name: '2.002 Wissensnetze',
        sectionId: 4,
        resolution: '3x4k',
        theme: 'black',
        width: 3 * 3840,
        height: 2160,
        orientation: 'landscape',
    },
    {
        key: 'cupboards',
        name: '3.001 Schränke',
        sectionId: 5,
        resolution: 'fhd',
        theme: 'black',
        width: 1920,
        height: 1080,
        orientation: 'landscape',
    },
    {
        key: 'desk',
        name: '3.004 Schreibtisch',
        sectionId: 7,
        resolution: 'fhd',
        theme: 'black',
        width: 1920,
        height: 1080,
        orientation: 'landscape',
    },
    {
        key: 'atelier',
        name: '3.005 Atelier',
        sectionId: 8,
        resolution: 'fhd',
        theme: 'white',
        width: 1920,
        height: 1080,
        orientation: 'landscape',
    },
    {
        key: 'market',
        name: '3.009 Markt',
        sectionId: 10,
        resolution: '4k',
        theme: 'black',
        width: 3840,
        height: 2160,
        orientation: 'landscape',
    },
]

export { stationsData }
