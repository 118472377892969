import localforage from 'localforage'
import * as Promise from 'bluebird'
import api, { fetchAsBlob } from '../mixins/api'
import { blobToBase64 } from '../utils/image'

const getInitialState = () => {
    return {
        items: [],
        blobs: {},
        isolatedBlobs: {},
    }
}

// const blobQueue = []

export default {
    state: getInitialState(),
    namespaced: true,
    mutations: {
        setItems(state, items) {
            state.items = items
        },
        setBlobs(state, blobs) {
            const promises = []
            if (blobs) {
                const keys = Object.keys(blobs)
                keys.forEach(key => {
                    promises.push(blobToBase64(blobs[key]))
                })
                Promise.all(promises).then(response => {
                    const newBlobs = {}
                    response.forEach((blob, index) => {
                        newBlobs[keys[index]] = blob
                    })
                    state.blobs = newBlobs
                })
            }
        },
        setIsolatedBlobs(state, blobs) {
            const promises = []
            if (blobs) {
                const keys = Object.keys(blobs)
                keys.forEach(key => {
                    promises.push(blobToBase64(blobs[key]))
                })
                Promise.all(promises).then(response => {
                    const newBlobs = {}
                    response.forEach((blob, index) => {
                        newBlobs[keys[index]] = blob
                    })
                    state.isolatedBlobs = newBlobs
                })
            }
        },
    },
    actions: {
        async init({ commit }) {
            console.log('init items store')

            api.goettingen.getAllItems().then(response => {
                const items = response.data.docs.map(item => {
                    return {
                        goettItem: item,
                    }
                })
                commit('setItems', items)
                console.log('fetched all items (' + items.length + ')')
            })

            try {
                console.log('loading blobs')
                localforage
                    .getItem('blobs')
                    .then(blobs => {
                        console.log('loaded blobs from local forage')
                        return commit('setBlobs', blobs)
                    })
                    .catch(error => {
                        console.log('could not load blobs', error)
                    })
            } catch (err) {
                console.log(err)
            }

            try {
                console.log('loading isolated blobs')
                localforage
                    .getItem('isolatedBlobs')
                    .then(blobs => {
                        console.log('loaded isolated blobs from local forage')
                        return commit('setIsolatedBlobs', blobs)
                    })
                    .catch(error => {
                        console.log('could not load isolated blobs', error)
                    })
            } catch (err) {
                console.log(err)
            }
        },
        initBlobs({ commit, state }) {
            console.log('fetching all blobs')
            const size = 512
            Promise.map(
                state.items.slice(0, 1),
                item => {
                    return fetchAsBlob(
                        api.goettingen.getThumbnailUrl(
                            item.goettItem,
                            size,
                            size,
                        ),
                    )
                },
                { concurrency: 156 },
            )
                .then(response => {
                    const blobs = {}
                    response.forEach((blob, index) => {
                        const item = state.items[index]
                        blobs[item.goettItem.PI] = blob
                    })
                    console.log('fetched all blobs', blobs)
                    localforage
                        .setItem('blobs', blobs)
                        .then(() => {
                            console.log('successfully stored blobs')
                        })
                        .catch(error => {
                            console.log('could not store blobs', error)
                        })
                    commit('setBlobs', blobs)
                })
                .catch(error => {
                    console.log('could not fetch blobs', error)
                })
        },
    },
    getters: {
        getItems: state => state.items,
        getBlob: state => ({ item, width, height }) => {
            //     TODO: fetch image async and add to localstorage
            //     TODO: queue e.g. when fetching 900 coins, maybe doable with bluebird, p-queue, ...
            const { PI } = item.goettItem
            //     fetchAsBlob(
            //         isolated
            //             ? api.goettingen.getIsolatedThumbnailUrl(
            //                   item.goettItem,
            //                   width,
            //               )
            //             : api.goettingen.getThumbnailUrl(
            //                   item.goettItem,
            //                   width,
            //                   height,
            //               ),
            //     ).then(response => {
            //         localforage.setItem(`blob-${PI}`, response)
            //         // TODO: add to store
            //     })

            const blob = state.blobs[PI]
            if (blob) {
                return blob
            } else {
                return api.goettingen.getThumbnailUrl(
                    item.goettItem,
                    width,
                    height,
                )
            }
        },
        getIsolatedBlob: state => ({ item, width }) => {
            //     TODO: fetch image async and add to localstorage
            //     TODO: queue e.g. when fetching 900 coins, maybe doable with bluebird, p-queue, ...
            const { PI } = item.goettItem

            const blob = state.isolatedBlobs[PI]
            if (blob) {
                return blob
            } else {
                return api.goettingen.getIsolatedThumbnailUrl(
                    item.goettItem,
                    width,
                )
            }
        },
    },
}
