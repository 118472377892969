import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default {
    namespaced: true,
    state: {
        assets: [],
    },
    mutations: {
        setAsset(state, asset) {
            state.assets.push(asset)
        },
    },
    actions: {
        setAsset({ commit }, asset) {
            commit('setAsset', asset)
        },
    },
    getters: {},
}
